// src/components/ViewRegistrations.js
import React, { useEffect, useState, useCallback } from "react";
import { db } from "../firebase";
import { motion } from "framer-motion";
import { collection, getDocs, query, where } from "firebase/firestore";

const ViewRegistrations = () => {
  const [registrations, setRegistrations] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);

  const fetchEvents = useCallback(async () => {
    const eventsCollection = collection(db, "events");
    const eventSnapshot = await getDocs(eventsCollection);
    const eventList = eventSnapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title,
    }));
    setEvents(eventList);
  }, []);

  const fetchRegistrations = useCallback(async () => {
    if (selectedEvent === "") {
      setRegistrations([]);
      return;
    }
    const registrationsRef = collection(db, "registrations");
    const q = query(registrationsRef, where("eventId", "==", selectedEvent));
    const registrationSnapshot = await getDocs(q);
    const registrationList = registrationSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setRegistrations(registrationList);
  }, [selectedEvent]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    fetchRegistrations();
  }, [selectedEvent, fetchRegistrations]);

  return (
    <motion.div
      className="w-full"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div className="mt-6 bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">View Registrations</h2>
        <div className="mb-4">
          <label className="block mb-1">Select Event</label>
          <select
            className="w-full p-2 border rounded"
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
          >
            <option value="">-- Select an Event --</option>
            {events.map((event) => (
              <option key={event.id} value={event.id}>
                {event.title}
              </option>
            ))}
          </select>
        </div>
        {selectedEvent && (
          <div>
            {registrations.length === 0 ? (
              <p>No registrations for this event.</p>
            ) : (
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">User ID</th>
                    <th className="px-4 py-2">Registration Data</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations.map((reg) => (
                    <tr key={reg.id} className="text-center">
                      <td className="border px-4 py-2">{reg.userId}</td>
                      <td className="border px-4 py-2">
                        {Object.entries(reg.answers).map(
                          ([key, value], index) => (
                            <div key={index}>
                              <strong>{key}:</strong>{" "}
                              {Array.isArray(value) ? value.join(", ") : value}
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ViewRegistrations;