// src/pages/AdminDashboard.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaPlus, FaList, FaUsers, FaSignOutAlt, FaTachometerAlt } from 'react-icons/fa';
import AddNewEvent from '../components/AddNewEvent';
import ExistingEvents from '../components/ExistingEvents';
import ViewRegistrations from '../components/ViewRegistrations';
import Dashboard from '../components/Dashboard';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [modal, setModal] = useState({ isVisible: false, title: "", message: "", onConfirm: null });
  const navigate = useNavigate();

  const handleSignOut = () => {
    setModal({
      isVisible: true,
      title: 'Confirm Sign Out',
      message: 'Are you sure you want to sign out?',
      onConfirm: confirmSignOut
    });
  };

  const confirmSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      setModal({
        isVisible: true,
        title: 'Error',
        message: 'Failed to sign out. Please try again.',
        onConfirm: null
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Fixed Sidebar */}
      <div className="w-64 bg-white shadow-md fixed h-full">
        <div className="p-6 text-2xl font-bold">YouthXConnect <br/> Events <br/>
          Admin Dashboard</div>
        <nav className="mt-10">
          <button
            onClick={() => setActiveTab('dashboard')}
            className={`w-full flex items-center px-6 py-4 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
              activeTab === 'dashboard' ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
            }`}
          >
            <FaTachometerAlt className="mr-3" />
            <span>Dashboard</span>
          </button>
          <button
            onClick={() => setActiveTab('events')}
            className={`w-full flex items-center px-6 py-4 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
              activeTab === 'events' ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
            }`}
          >
            <FaList className="mr-3" />
            <span>Events</span>
          </button>
          <button
            onClick={() => setActiveTab('new')}
            className={`w-full flex items-center px-6 py-4 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
              activeTab === 'new' ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
            }`}
          >
            <FaPlus className="mr-3" />
            <span>Add New Event</span>
          </button>
          <button
            onClick={() => setActiveTab('registrations')}
            className={`w-full flex items-center px-6 py-4 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
              activeTab === 'registrations' ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
            }`}
          >
            <FaUsers className="mr-3" />
            <span>Registrations</span>
          </button>
        </nav>
        <div className="p-6 border-t">
          <button
            onClick={handleSignOut}
            className="w-full flex items-center justify-center px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            <FaSignOutAlt className="mr-2" />
            Sign Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-64 overflow-auto">
        <div className="p-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {activeTab === 'dashboard' && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-6">Dashboard</h2>
                <Dashboard />
              </div>
            )}
            {activeTab === 'events' && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-6">Existing Events</h2>
                <ExistingEvents />
              </div>
            )}
            {activeTab === 'new' && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-6">Add New Event</h2>
                <AddNewEvent />
              </div>
            )}
            {activeTab === 'registrations' && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-semibold mb-6">View Registrations</h2>
                <ViewRegistrations />
              </div>
            )}
          </motion.div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        isVisible={modal.isVisible}
        title={modal.title}
        message={modal.message}
        onClose={() => setModal({ isVisible: false, title: "", message: "", onConfirm: null })}
        onConfirm={modal.onConfirm}
      />
    </div>
  );
};

export default AdminDashboard;