// src/pages/EditEventPage.js
import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Modal from '../components/Modal';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';

const FIELD_NAMES = {
  LAST_DATE: 'lastDateToRegister',
  EVENT_DATE: 'eventDate'
};

const normalizeQuestion = (q) => {
  const settings = q.settings || {};
  return {
    questionText: q.question || q.questionText || '',
    questionType: q.type || q.questionType || 'text',
    options: ['dropdown', 'checkbox', 'radio'].includes(q.type || q.questionType) ? (q.options || []) : [],
    maxLength: settings.maxLength || null,
    maxChecks: settings.maxChecks || null,
    minValue: settings.minValue || null,
    maxValue: settings.maxValue || null,
    minDate: settings.minDate || null,
    maxDate: settings.maxDate || null
  };
};

const validateAndConvertDate = (dateString) => {
  return dateString ? Timestamp.fromDate(new Date(dateString)) : null;
};

const EditEventPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    venue: '',
    lastDateToRegister: '',
    eventDate: '',
    questions: [],
    bannerImageUrl: '',
    price: '0',
  });
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState('text');
  const [options, setOptions] = useState(['']);
  const [newBannerImage, setNewBannerImage] = useState(null);
  const [modal, setModal] = useState({ isVisible: false, title: "", message: "", onConfirm: null });
  const [coupons, setCoupons] = useState([]);
  const [questionSettings, setQuestionSettings] = useState({
    maxLength: '',
    maxChecks: '',
    minDate: '',
    maxDate: '',
    minValue: '',
    maxValue: '',
    maxSelections: '',
  });
  const [eventSections, setEventSections] = useState([]);
  const [newSection, setNewSection] = useState({ title: '', content: '', order: 0 });
  const [previewMode, setPreviewMode] = useState(false);
  const [newCoupon, setNewCoupon] = useState({ code: '', discount: '', maxUses: 1 });
  const [editingCouponIndex, setEditingCouponIndex] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const eventRef = doc(db, 'events', id);
      const eventSnap = await getDoc(eventRef);
      if (eventSnap.exists()) {
        const data = eventSnap.data();
        setCoupons(data.coupons?.map(coupon => ({
          ...coupon,
          discount: coupon.discount.toString()
        })) || []);
        setEventData({
          title: data.title || '',
          description: data.description || '',
          venue: data.venue || '',
          [FIELD_NAMES.LAST_DATE]: data[FIELD_NAMES.LAST_DATE] instanceof Timestamp ? 
            format(data[FIELD_NAMES.LAST_DATE].toDate(), 'yyyy-MM-dd') : '',
          [FIELD_NAMES.EVENT_DATE]: data[FIELD_NAMES.EVENT_DATE] instanceof Timestamp ? 
            format(data[FIELD_NAMES.EVENT_DATE].toDate(), 'yyyy-MM-dd') : '',
          questions: data.questions?.map(q => ({
            question: q.questionText || q.question,
            type: q.questionType || q.type,
            options: q.options || [],
            settings: {
              maxLength: q.maxLength,
              maxChecks: q.maxChecks,
              minValue: q.minValue,
              maxValue: q.maxValue,
              minDate: q.minDate,
              maxDate: q.maxDate
            }
          })) || [],
          bannerImageUrl: data.bannerImage || data.bannerImageUrl || '',
          price: (data.price?.toString() || '0'),
        });
        setEventSections(
          (data.eventSections || []).map(section => ({
            id: section.sectionId || section.id || Date.now(),
            title: section.title || '',
            content: section.content || '',
            order: section.order || 0
          })).sort((a, b) => a.order - b.order)
        );
      } else {
        setModal({ isVisible: true, title: "Error", message: "Event not found.", onConfirm: () => navigate('/admin') });
      }
    };
    fetchEvent();
  }, [id, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1048576) { // 1MB
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 290 && img.height >= 160) {
          setNewBannerImage(file);
        } else {
          setModal({ isVisible: true, title: "Invalid Image", message: "Image dimensions should be at least 290x160px." });
        }
      };
    } else {
      setModal({ isVisible: true, title: "Invalid File", message: "File size should not exceed 1MB." });
    }
  };

  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `banners/${id}_${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...eventData.questions];
    updatedQuestions.splice(index, 1);
    setEventData({ ...eventData, questions: updatedQuestions });
  };

  const removeBanner = () => {
    setModal({
      isVisible: true,
      title: "Confirm Removal",
      message: "Are you sure you want to remove the banner image?",
      onConfirm: async () => {
        if (eventData.bannerImageUrl) {
          try {
            const imageRef = ref(storage, eventData.bannerImageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('Error deleting image:', error);
            setModal({ isVisible: true, title: "Error", message: "Failed to remove banner image.", onConfirm: null });
            return;
          }
        }
        setEventData({ ...eventData, bannerImageUrl: '' });
        setNewBannerImage(null);
        setModal({ isVisible: false, title: "", message: "", onConfirm: null });
      }
    });
  };

  const addQuestion = () => {
    if (question.trim() === '') return;
    let newQuestion = { question, type: questionType, options: [] };
    if (['dropdown', 'checkbox', 'radio'].includes(questionType)) {
      newQuestion.options = options.filter(opt => opt.trim() !== '');
    }
    setEventData({
      ...eventData,
      questions: [...eventData.questions, newQuestion],
    });
    setQuestion('');
    setQuestionType('text');
    setOptions(['']);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, '']);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleSectionAdd = () => {
    if (!newSection.title.trim() || !newSection.content.trim()) return;
    setEventSections([...eventSections, { ...newSection, id: Date.now() }]);
    setNewSection({ title: '', content: '', order: eventSections.length });
  };

  const handleSectionRemove = (sectionId) => {
    setEventSections(eventSections.filter(section => section.id !== sectionId));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setModal({
      isVisible: true,
      title: "Confirm Update",
      message: "Are you sure you want to update this event?",
      onConfirm: async () => {
        try {
          let bannerImageUrl = eventData.bannerImageUrl;
          if (newBannerImage) {
            if (eventData.bannerImageUrl) {
              try {
                const oldImageRef = ref(storage, eventData.bannerImageUrl);
                await deleteObject(oldImageRef);
              } catch (error) {
                console.error('Error deleting old image:', error);
              }
            }
            bannerImageUrl = await handleImageUpload(newBannerImage);
          }

          const updateData = {
            title: eventData.title || '',
            description: eventData.description || '',
            venue: eventData.venue || '',
            bannerImage: bannerImageUrl || '',
            bannerImageUrl: bannerImageUrl || '',
            price: Number(eventData.price) || 0,
            updatedAt: Timestamp.now(),
            [FIELD_NAMES.LAST_DATE]: validateAndConvertDate(eventData.lastDateToRegister),
            [FIELD_NAMES.EVENT_DATE]: validateAndConvertDate(eventData.eventDate),
          };

          // Only include dates if they are valid
          const lastDate = validateAndConvertDate(eventData[FIELD_NAMES.LAST_DATE]);
          if (lastDate) {
            updateData[FIELD_NAMES.LAST_DATE] = lastDate;
          }

          const eventDate = validateAndConvertDate(eventData[FIELD_NAMES.EVENT_DATE]);
          if (eventDate) {
            updateData[FIELD_NAMES.EVENT_DATE] = eventDate;
          }

          // Only include questions if they exist
          if (Array.isArray(eventData.questions)) {
            updateData.questions = eventData.questions.map(normalizeQuestion);
          }

          // Only include coupons if they exist
          if (Array.isArray(coupons)) {
            updateData.coupons = coupons.map(coupon => ({
              ...coupon,
              discount: Number(coupon.discount) || 0,
              maxUses: Number(coupon.maxUses) || 0
            }));
          }

          // Only include event sections if they exist
          if (Array.isArray(eventSections)) {
            updateData.eventSections = eventSections.map((section, index) => ({
              sectionId: section.id || Date.now().toString(),
              id: section.id || Date.now().toString(),
              title: section.title || '',
              content: section.content || '',
              order: section.order || index
            }));
          }

          const eventRef = doc(db, 'events', id);
          await updateDoc(eventRef, updateData);
          
          setModal({ isVisible: true, title: "Success", message: "Event updated successfully!", onConfirm: () => navigate('/admin') });
        } catch (error) {
          console.error('Error updating event:', error);
          setModal({ isVisible: true, title: "Error", message: `Failed to update event: ${error.message}`, onConfirm: null });
        }
      }
    });
  };

  const QUESTION_TYPES = ['text', 'textarea', 'number', 'dropdown', 'checkbox', 'radio', 'date'];

  const renderQuestionSettings = () => {
    switch (questionType) {
      case 'text':
      case 'textarea':
        return (
          <div className="mt-2">
            <label>Max Answer Length</label>
            <input
              type="number"
              min="1"
              className="w-full p-2 border rounded"
              value={questionSettings.maxLength}
              onChange={(e) => setQuestionSettings({ ...questionSettings, maxLength: e.target.value })}
            />
          </div>
        );
      case 'number':
        return (
          <div className="mt-2 space-y-2">
            <div>
              <label>Minimum Value</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={questionSettings.minValue}
                onChange={(e) => setQuestionSettings({ ...questionSettings, minValue: e.target.value })}
              />
            </div>
            <div>
              <label>Maximum Value</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={questionSettings.maxValue}
                onChange={(e) => setQuestionSettings({ ...questionSettings, maxValue: e.target.value })}
              />
            </div>
          </div>
        );
      case 'checkbox':
        return (
          <div className="mt-2">
            <label>Max Selections</label>
            <input
              type="number"
              min="1"
              className="w-full p-2 border rounded"
              value={questionSettings.maxChecks}
              onChange={(e) => setQuestionSettings({ ...questionSettings, maxChecks: e.target.value })}
            />
          </div>
        );
      case 'date':
        return (
          <div className="mt-2 space-y-2">
            <div>
              <label>Minimum Date (Optional)</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                value={questionSettings.minDate}
                onChange={(e) => setQuestionSettings({ ...questionSettings, minDate: e.target.value })}
              />
            </div>
            <div>
              <label>Maximum Date (Optional)</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                value={questionSettings.maxDate}
                onChange={(e) => setQuestionSettings({ ...questionSettings, maxDate: e.target.value })}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleAddCoupon = () => {
    if (!newCoupon.code || !newCoupon.discount || !newCoupon.maxUses) {
      setModal({ isVisible: true, title: "Invalid Input", message: "Please fill all coupon fields." });
      return;
    }
    setCoupons([...coupons, newCoupon]);
    setNewCoupon({ code: '', discount: '', maxUses: 1 });
  };

  const handleRemoveCoupon = (index) => {
    const updatedCoupons = [...coupons];
    updatedCoupons.splice(index, 1);
    setCoupons(updatedCoupons);
  };

  const handleEditCoupon = (index) => {
    setEditingCouponIndex(index);
    setNewCoupon(coupons[index]);
  };

  const handleUpdateCoupon = () => {
    if (!newCoupon.code || !newCoupon.discount || !newCoupon.maxUses) {
      setModal({ isVisible: true, title: "Invalid Input", message: "Please fill all coupon fields." });
      return;
    }
    const updatedCoupons = [...coupons];
    updatedCoupons[editingCouponIndex] = newCoupon;
    setCoupons(updatedCoupons);
    setEditingCouponIndex(null);
    setNewCoupon({ code: '', discount: '', maxUses: 1 });
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 flex justify-center">
      <motion.div
        className="bg-white p-6 rounded shadow-md w-full max-w-2xl"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <h2 className="text-2xl font-semibold mb-4">Edit Event</h2>
        <form onSubmit={handleUpdate}>
          {/* Event Title */}
          <div className="mb-4">
            <label className="block mb-1">Event Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.title}
              onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
              required
            />
          </div>
          {/* Event Description */}
          <div className="mb-4">
            <label className="block mb-1">Event Description</label>
            <textarea
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.description}
              onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
              required
              rows={4}
            ></textarea>
          </div>
          {/* Venue */}
          <div className="mb-4">
            <label className="block mb-1">Venue</label>
            <input
              type="text"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.venue}
              onChange={(e) => setEventData({ ...eventData, venue: e.target.value })}
              required
            />
          </div>
          {/* Registration Price */}
          <div className="mb-4">
            <label className="block mb-1">Registration Price (₹)</label>
            <input
              type="number"
              min="0"
              step="1"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.price}
              onChange={(e) => setEventData({ ...eventData, price: e.target.value })}
              required
              placeholder="Enter registration price"
            />
            <span className="text-sm text-gray-500">Set 0 for free registration</span>
          </div>
          {/* Last Day for Registration */}
          <div className="mb-4">
            <label className="block mb-1">Last Day for Registration</label>
            <input
              type="date"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.lastDateToRegister}
              onChange={(e) => setEventData({ ...eventData, lastDateToRegister: e.target.value })}
              required
            />
          </div>
          {/* Day of the Event */}
          <div className="mb-4">
            <label className="block mb-1">Day of the Event</label>
            <input
              type="date"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventData.eventDate}
              onChange={(e) => setEventData({ ...eventData, eventDate: e.target.value })}
              required
            />
          </div>
          {/* Banner Image */}
          <div className="mb-4">
            <label className="block mb-1">Banner Image</label>
            {eventData.bannerImageUrl && (
              <div className="mb-2">
                <img 
                  src={eventData.bannerImageUrl} 
                  alt="Current banner" 
                  className="w-[290px] h-[160px] object-cover mb-2 rounded"
                />
                <button
                  type="button"
                  onClick={removeBanner}
                  className="bg-red-500 text-white px-3 py-1 rounded text-sm hover:bg-red-600 transition-colors"
                >
                  Remove Banner
                </button>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full"
            />
            {newBannerImage && (
              <p className="text-sm text-gray-600 mt-2">New image selected for upload.</p>
            )}
          </div>
          {/* Custom Registration Questions */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Custom Registration Questions</h3>
            {eventData.questions.map((q, index) => (
              <div key={index} className="mb-2 flex items-center justify-between">
                <span className="font-medium">
                  {index + 1}. {q.question} ({q.type})
                </span>
                <button
                  type="button"
                  onClick={() => removeQuestion(index)}
                  className="bg-red-500 text-white px-2 py-1 rounded text-sm hover:bg-red-600 transition-colors"
                >
                  Remove
                </button>
              </div>
            ))}
            {/* Add Question Section */}
            <div className="border p-4 rounded mt-4">
              <div className="flex flex-col mb-2">
                <label className="mb-1">Question</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Enter your question"
                />
              </div>
              <div className="flex flex-col mb-2">
                <label className="mb-1">Question Type</label>
                <select
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value)}
                >
                  {QUESTION_TYPES.map(type => (
                    <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                  ))}
                </select>
              </div>
              {["dropdown", "checkbox", "radio"].includes(questionType) && (
                <div className="flex flex-col mb-2">
                  <label className="mb-1">Options</label>
                  {options.map((opt, idx) => (
                    <div key={idx} className="flex items-center mb-1">
                      <input
                        type="text"
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        value={opt}
                        onChange={(e) => handleOptionChange(idx, e.target.value)}
                        placeholder={`Option ${idx + 1}`}
                      />
                      <button
                        type="button"
                        onClick={() => removeOption(idx)}
                        className="ml-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition-colors"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addOption}
                    className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                  >
                    Add Option
                  </button>
                </div>
              )}
              {renderQuestionSettings()}
              <button
                type="button"
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                onClick={addQuestion}
              >
                Add Question
              </button>
            </div>
          </div>
          {/* Event Information Sections */}
          <div className="mb-6 border-t pt-4">
            <h3 className="text-xl font-semibold mb-4">Event Information Sections</h3>
            
            {/* Existing Sections */}
            {eventSections.map((section, index) => (
              <div key={section.id} className="mb-4 p-4 border rounded">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-semibold">{section.title}</h4>
                  <button
                    type="button"
                    onClick={() => handleSectionRemove(section.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
                {previewMode ? (
                  <div className="prose max-w-none">
                    <ReactMarkdown>{section.content}</ReactMarkdown>
                  </div>
                ) : (
                  <textarea
                    value={section.content}
                    onChange={(e) => {
                      const updatedSections = [...eventSections];
                      updatedSections[index].content = e.target.value;
                      setEventSections(updatedSections);
                    }}
                    className="w-full p-2 border rounded min-h-[100px]"
                  />
                )}
              </div>
            ))}

            {/* Add New Section */}
            <div className="border p-4 rounded">
              <h4 className="font-semibold mb-2">Add New Section</h4>
              <input
                type="text"
                placeholder="Section Title"
                value={newSection.title}
                onChange={(e) => setNewSection({ ...newSection, title: e.target.value })}
                className="w-full p-2 border rounded mb-2"
              />
              <textarea
                placeholder="Section Content (Markdown supported)"
                value={newSection.content}
                onChange={(e) => setNewSection({ ...newSection, content: e.target.value })}
                className="w-full p-2 border rounded mb-2 min-h-[100px]"
              />
              <button
                type="button"
                onClick={handleSectionAdd}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Add Section
              </button>
            </div>

            {/* Preview Toggle */}
            <button
              type="button"
              onClick={() => setPreviewMode(!previewMode)}
              className="mt-4 bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            >
              {previewMode ? 'Edit Mode' : 'Preview Mode'}
            </button>
          </div>
          {/* Coupon Management */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Coupons</h3>
            {coupons.map((coupon, index) => (
              <div key={index} className="flex items-center justify-between mb-2">
                <div>
                  <span className="font-medium">Code: {coupon.code}</span>
                  <span className="ml-4">Discount: {coupon.discount}%</span>
                  <span className="ml-4">Max Uses: {coupon.maxUses}</span>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleEditCoupon(index)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mr-2 hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRemoveCoupon(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}

            {/* Add/Edit Coupon Form */}
            <div className="border p-4 rounded mt-4">
              <h4 className="font-semibold mb-2">{editingCouponIndex !== null ? 'Edit Coupon' : 'Add New Coupon'}</h4>
              <div className="flex flex-col mb-2">
                <label className="mb-1">Coupon Code</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={newCoupon.code}
                  onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
                  placeholder="Enter coupon code"
                />
              </div>
              <div className="flex flex-col mb-2">
                <label className="mb-1">Discount (%)</label>
                <input
                  type="number"
                  min="0"
                  max="100"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={newCoupon.discount}
                  onChange={(e) => setNewCoupon({ ...newCoupon, discount: e.target.value })}
                  placeholder="Enter discount percentage"
                />
              </div>
              <div className="flex flex-col mb-2">
                <label className="mb-1">Max Uses</label>
                <input
                  type="number"
                  min="1"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={newCoupon.maxUses}
                  onChange={(e) => setNewCoupon({ ...newCoupon, maxUses: e.target.value })}
                  placeholder="Enter maximum uses"
                />
              </div>
              {editingCouponIndex !== null ? (
                <button
                  type="button"
                  onClick={handleUpdateCoupon}
                  className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Update Coupon
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleAddCoupon}
                  className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                  Add Coupon
                </button>
              )}
              {editingCouponIndex !== null && (
                <button
                  type="button"
                  onClick={() => {
                    setEditingCouponIndex(null);
                    setNewCoupon({ code: '', discount: '', maxUses: 1 });
                  }}
                  className="mt-2 ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-colors"
          >
            Update Event
          </button>
        </form>
        <Modal
          isVisible={modal.isVisible}
          title={modal.title}
          message={modal.message}
          onClose={() => setModal({ isVisible: false, title: "", message: "", onConfirm: null })}
          onConfirm={modal.onConfirm}
        />
      </motion.div>
    </div>
  );
};

export default EditEventPage;